*{
    margin: 0px;
    padding: 0px;

}
.backcolor{
    background-color: #eeeeee;
}
.bg{
    border: 1px solid black;
}
.head3{
    font-weight: 500;
    color: rgb(81, 81, 255);
    font-size: x-large;
    /* font-family: Arial, Helvetica, sans-serif; */
    font-weight: bold;
}
.para{
    font-size: 22px;
    /* font-family: 'Montserrat',Helvetica,Arial,Lucida,sans-serif; */
    font-weight: 200;
}
.para2{
    font-size: 24px;
    font-weight: 200;
    /* font-family: 'Montserrat',Helvetica,Arial,Lucida,sans-serif; */
}
.list{
    font-size: 22px;
    font-weight: 200;
    /* font-family: 'Montserrat',Helvetica,Arial,Lucida,sans-serif; */
}
.line{
    line-height: 2.5em;

}