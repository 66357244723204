
*{
    margin: 0px;
    padding: 0px;
 

    

}
body{
    background-color: #eeeeee;
    overflow-x: hidden;
}
.bg1{
   /* border: 1px solid black; */
}
.bg2{
    background-color: chartreuse;

    border: 1px solid red;
   
}
.bg3{
    background-color:whitesmoke;
}

.san input{
    font-weight: 200;
}
.san3{
    /* border: 1px solid black; */
    background-color: #eeeeee;
    min-height: 400px;
    /* height: auto; */
}

.san3 h1{
       font-size: 55px;
       font-weight: 200;

}
.p50{
    padding: 40px;
}
.bgyellow{
    background-color: rgb(255, 200, 48);
    font-weight: unset;
    border-radius: 10px; 
   /* border: 1px solid black; */
   /* margin-left: 150px;
   margin-top: 20px; */

}

.san2{
    height: 500px;
    border: 1px solid black;
}
::-webkit-input-placeholder {
    font-size: 18px;
    font-family: 'Montserrat',Helvetica,Arial,Lucida,sans-serif;
 }

.san4{
        /* border: 1px solid black; */
        background-color: #eeeeee;
        /* font-family: 'Montserrat',Helvetica,Arial,Lucida,sans-serif; */
        /* font-weight: unset; */
        font-size: 23px;
        font-weight: 200;
}

.br{
    border-radius: 30px;
}
.linkitem{
    /* font-family: 'Montserrat',Helvetica,Arial,Lucida,sans-serif; */
    text-decoration:  none;
    font-size: 23px;
    color: white;
    list-style:  none;
    font-weight: 200;
}

.linkitem:hover {
    color: rgb(255, 200, 48);

}
.h4{
    width: 200px;
    height: 180px;
    /* border: 1px solid black; */
    margin-left: -50px;
    position: absolute;
    /* background-color: white; */
    background-color: #232324;
   padding-top: 5px;
   border-radius: 10px;
}
.h4{
    display: none;
} 
.h2 :nth-child(3) :hover .h4{
     display: block;
}


.form-control:focus {
    border-color: white !important;
    /* box-shadow: 0 0 5px rgb(50, 187, 72) !important; */
    box-shadow: none !important;
    outline: none!important;
  }
@media (max-width:780px) {
    .san3 h1{
        font-size: 35px;
    }
}
